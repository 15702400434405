import { PayloadAction } from "@reduxjs/toolkit";
import { configApi, SmtpConfigDto } from "api/ConfigApi";
import { handleSagaError } from "helpers/handleSagaError";
import { toast } from "react-toastify";
import { put } from "redux-saga/effects";
import { setPendingSettingState } from "../settings.slice";

export function* saveSmtpSettingsSaga(action: PayloadAction<SmtpConfigDto>) {
  try {
    yield put(setPendingSettingState({ key: "smtp", value: true }));
    yield configApi.updateConfig(action.payload);
    toast.success("SMTP settings saved successfully");
  } catch (error) {
    handleSagaError(error);
  } finally {
    yield put(setPendingSettingState({ key: "smtp", value: false }));
  }
}
