import { ActionFromReducersMapObject, combineReducers, Reducer, StateFromReducersMapObject } from "@reduxjs/toolkit";
import { channelsReducer } from "redux/channels/channelsSlice";
import { cronTestsReducer } from "redux/cronTests/cronTestsSlice";
import { statisticsReducer } from "redux/statistics/statisticsSlice";
import { userReducer } from "redux/user/userSlice";
import { catalogReducer } from "./catalogs/catalogSlice";
import { customTariffsReducer } from "./customTariffsPage/customTariffsPageSlice";
import { settingsReducer } from "./settings/settings.slice";
import { userPageReducer } from "./userPage/userPageSlice";

const reducers = {
  statistics: statisticsReducer,
  user: userReducer,
  userPage: userPageReducer,
  catalog: catalogReducer,
  customTariffs: customTariffsReducer,
  channels: channelsReducer,
  cronTests: cronTestsReducer,
  settings: settingsReducer,
};

type ReducersMapObject = typeof reducers;

export const rootReducer: Reducer<
  StateFromReducersMapObject<ReducersMapObject>,
  ActionFromReducersMapObject<ReducersMapObject>
> = combineReducers(reducers);

export type TStore = ReturnType<typeof rootReducer>;
