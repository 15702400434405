import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SmtpConfigDto } from "api/ConfigApi";

const initialState = {
  smtp: null as SmtpConfigDto | null,
  pendingState: {
    smtp: false,
  },
};

export type SettingsPendingKeys = keyof typeof initialState.pendingState;

const settingsSlice = createSlice({
  name: "settings",
  initialState: initialState,
  reducers: {
    setSmtpSettings(state, action: PayloadAction<SmtpConfigDto>) {
      return { ...state, smtp: action.payload };
    },
    setPendingSettingState(state, action: PayloadAction<{ key: SettingsPendingKeys; value: boolean }>) {
      return { ...state, pendingState: { ...state.pendingState, [action.payload.key]: action.payload.value } };
    },
  },
});

export const settingsReducer = settingsSlice.reducer;
export const { setSmtpSettings, setPendingSettingState } = settingsSlice.actions;
