import { dashClient } from "helpers/HttpClient";

export type SmtpConfigDto = {
  host: string;
  port: number;
  senderEmail: string;
  secure: boolean;
  user?: string | null;
  password?: string | null;
  formateFrom?: string | null;
};

class ConfigApi {
  private readonly dashClinet = dashClient;

  async getConfig() {
    const response = await this.dashClinet.apiGet<SmtpConfigDto | null>({ url: "/admin/settings/smtp" });
    return typeof response === "object" ? response : null;
  }

  async updateConfig(data: SmtpConfigDto) {
    return this.dashClinet.apiPut({ url: "/admin/settings/smtp", data });
  }
}

export const configApi = new ConfigApi();
