import { SmtpConfigDto } from "api/ConfigApi";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectPendingSettingState, selectSmtpSettings } from "redux/settings/selectors/selectSettingsState";
import { getSmtpConfigs, saveSmtpConfigs } from "redux/settings/settingsActions";
import { YupValidator } from "shared/src/common/yup";
import Checkbox from "shared/src/components/Checkbox/Checkbox";
import { ControlledText } from "shared/src/components/ControlledElements/ControlledElements";
import { Heading } from "shared/src/components/Heading/Heading";
import { PbButton } from "shared/src/components/PbButton/PbButton";
import * as Yup from "yup";
import styles from "./styles.module.scss";

const schema: Yup.SchemaOf<SmtpConfigDto> = Yup.object().shape({
  host: Yup.string().required().trim(),
  port: Yup.number().required(),
  senderEmail: Yup.string().email().required(),
  user: Yup.string().nullable(),
  password: Yup.string().nullable(),
  formateFrom: Yup.string().nullable(),
  secure: Yup.boolean().required().default(false),
});

const SmtpSettingsPage: React.FC = () => {
  const smtpState = useSelector(selectSmtpSettings);
  const pending = useSelector(selectPendingSettingState("smtp"));

  const { trigger, getValues, control, watch, reset } = useForm({
    resolver: YupValidator(schema),
    defaultValues: smtpState
      ? smtpState
      : {
          password: null,
          user: null,
          formateFrom: null,
          secure: false,
        },
  });

  const { senderEmail, formateFrom, secure } = watch();

  async function handleSubmit() {
    if (!(await trigger())) return;
    const values = getValues();
    dispatch(saveSmtpConfigs(values));
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSmtpConfigs());
  }, []);

  useEffect(() => {
    if (smtpState) {
      reset(smtpState);
    }
  }, [smtpState]);

  return (
    <div style={{ width: "100%" }} className="page">
      <Heading variant="h2">SMTP Configs</Heading>

      <div className={styles.wrapper}>
        <ControlledText control={control} name="host" label="Host" required />
        <ControlledText control={control} name="port" label="Port" required />
        <ControlledText control={control} name="senderEmail" label="Sender Email" required />
        <ControlledText
          control={control}
          name="formateFrom"
          label={`Formate From ${formateFrom ?? ""} <${senderEmail ?? ""}>`}
        />
        <ControlledText disabled={!secure} control={control} name="user" label="User" />
        <ControlledText disabled={!secure} control={control} name="password" label="Password" />

        <div className={styles.secure}>
          <Controller
            name="secure"
            control={control}
            render={({ field }) => (
              <Checkbox
                name="secure"
                id="secure"
                checked={field.value}
                onChange={() => field.onChange(!field.value)}
                label="Secure"
              />
            )}
          />
        </div>

        <PbButton loading={pending} disabled={pending} onClick={handleSubmit}>
          Save
        </PbButton>
      </div>
    </div>
  );
};

export default SmtpSettingsPage;
