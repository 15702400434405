import { CreditCard, GoogleDocs, LogOut, Mail, TestTube, User, ViewColumns2 } from "iconoir-react";
import React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { RouteEnums } from "router/routeEnums";
import { logOut } from "../../redux/user/userActions";
import styles from "./navigationSideBar.module.scss";

type NavItemProps = { to: string; icon: React.ReactNode; title: string };

const menuItems: NavItemProps[] = [
  { to: "/", icon: <GoogleDocs className={styles.navigationIcon} />, title: "Statistics" },
  { to: RouteEnums.users, icon: <User className={styles.navigationIcon} />, title: "Users" },
  { to: RouteEnums.customTariffs, icon: <CreditCard className={styles.navigationIcon} />, title: "Custom tariffs" },
  { to: RouteEnums.channels, icon: <ViewColumns2 className={styles.navigationIcon} />, title: "Channels" },
  { to: RouteEnums.cronTests, icon: <TestTube className={styles.navigationIcon} />, title: "Cron tests" },
  { to: RouteEnums.smtpSettings, icon: <Mail className={styles.navigationIcon} />, title: "SMTP Configs" },
];

const NavItem: React.FC<NavItemProps> = (props) => {
  const { to, icon, title } = props;

  return (
    <li className={styles.navigationLink}>
      <NavLink className={({ isActive }) => (isActive ? styles.activeLink : "")} to={to}>
        {icon}
        {title}
      </NavLink>
    </li>
  );
};

export const NavigationSideBar: React.FC = () => {
  const dispatch = useDispatch();
  const logOutHandler = () => {
    dispatch(logOut());
  };

  return (
    <div className={styles.navigationSideBar}>
      <div className={styles.navigationListWrapper}>
        <p className={styles.navigationListTitle}>Admin panel</p>
        <ul className={styles.navigationList}>
          {menuItems.map((item, index) => (
            <NavItem key={index} to={item.to} icon={item.icon} title={item.title} />
          ))}
        </ul>
      </div>
      <div className={styles.navigationLink}>
        <button onClick={logOutHandler} className={styles.activeLink}>
          <LogOut className={styles.navigationIcon} />
          Logout
        </button>
      </div>
    </div>
  );
};
