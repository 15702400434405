import { configApi, SmtpConfigDto } from "api/ConfigApi";
import { handleSagaError } from "helpers/handleSagaError";
import { put } from "redux-saga/effects";
import { setSmtpSettings } from "../settings.slice";

export function* getSettingsSaga() {
  try {
    const settings: SmtpConfigDto = yield configApi.getConfig();
    yield put(setSmtpSettings(settings));
  } catch (e) {
    handleSagaError(e);
  }
}
