import { PayloadAction } from "@reduxjs/toolkit";
import { channelsApi } from "api/ChannelsApi";
import { ChannelsTypeList, CreateSupervisor } from "models/Channels";
import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { getSupervisorsOrPartnersList } from "redux/channels/channelsActions";
import { setChannelsLoading } from "redux/channels/channelsSlice";
import { parseError } from "shared/src/helpers/errorHandler";

export function* createSupervisorSaga(action: PayloadAction<CreateSupervisor>) {
  try {
    yield put(setChannelsLoading(true));
    yield call([channelsApi, channelsApi.createSupervisor], action.payload);
  } catch (e) {
    yield call([toast, toast.error], parseError(e)?.message);
    console.error(e);
  } finally {
    yield put(setChannelsLoading(false));
    yield put(getSupervisorsOrPartnersList({ type: ChannelsTypeList.supervisor }));
  }
}
