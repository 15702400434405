import { TStore } from "redux/reducer";
import { createSelector } from "reselect";
import { SettingsPendingKeys } from "../settings.slice";

export const selectSettingsState = (state: TStore) => state.settings;

export const selectSmtpSettings = createSelector([selectSettingsState], (state) => state.smtp);

export const selectPendingSettingState = (key: SettingsPendingKeys) =>
  createSelector([selectSettingsState], (state) => state.pendingState[key]);
