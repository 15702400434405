export enum RouteEnums {
  root = "/",
  users = "/users",
  user = "/users/:id",
  customTariffs = "/custom-tariffs",
  login = "/login",
  channels = "/channels",
  cronTests = "/cron-tests",
  smtpSettings = "/smtp-settings",
}
