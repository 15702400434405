import { fileSizeTsGuard, GetUsersQuery, UserAdminPage } from "api/UsersPageApi";
import { AddNewAccount } from "components/AddNewAccount/AddNewAccount";
import { AddUserToCompany } from "components/AddUserToCompany/AddUserToCompany";
import { EditUserForm } from "components/EditUserForm/EditUserForm";
import { format } from "date-fns";
import { CreateInvoice } from "features/CreateInvoice/CreateInvoice";
import { EditPencil, MoneySquare, Refresh, Union, WarningTriangleOutline } from "iconoir-react";
import { isNil, noop } from "lodash";
import { AccountFilters } from "models/AccountFilters";
import { CreateInvoiceDto } from "models/CreateInvoice.dto";
import { InvoiceType } from "models/InvoiceType";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MultiValue } from "react-select";
import { selectUsersPageState } from "redux/userPage/selectors/selectUsersState";
import {
  clearUsersTableCache,
  createInvoiceAction,
  extendUserTrialAction,
  getUsersAction,
  updateUserTableAction,
} from "redux/userPage/userPageActions";
import {
  setAccountFilters,
  setQuerySortAction,
  setSearchAction,
  setUserKyc,
  setUsersStatusGroupAction,
} from "redux/userPage/userPageSlice";
import Checkbox from "shared/src/components/Checkbox/Checkbox";
import { CommonTable, QuerySort, TableItem } from "shared/src/components/CommonTable/CommonTable";
import DropdownVertical, { DropItem } from "shared/src/components/DropdownVertical/DropdownVertical";
import { Heading } from "shared/src/components/Heading/Heading";
import { PageSwitch } from "shared/src/components/PageSwitch/PageSwitch";
import { PbButton } from "shared/src/components/PbButton/PbButton";
import { PerPageSwitcher } from "shared/src/components/PerPageSwitcher/PerPageSwitcher";
import { SearchInput } from "shared/src/components/SearchInput/SearchInput";
import { OptionType, SelectField } from "shared/src/components/Select/SelectField";
import { SelectMulti } from "shared/src/components/SelectMulti/SelectMulti";
import { prettySize } from "shared/src/helpers/utils";
import { UpdateUserDto } from "shared/src/models/UserModel";
import { ExtendTrialModal } from "./ExtendTrialModal";
import styles from "./UsersPage.module.scss";

export const safeDateFormat = (date?: Date, template = "P") => {
  if (isNil(date)) return;
  try {
    return format(date, template);
  } catch (err) {
    console.log("Parse date error");
    return "Invalid Date";
  }
};

const StatusOptions: Array<OptionType<GetUsersQuery["group"]>> = [
  { label: "All", value: "all" },
  { label: "Trial", value: "trial" },
  { label: "Active", value: "active" },
  { label: "Expired", value: "expired" },
  { label: "Payment Required", value: "payment_required" },
  { label: "Unsubscribed", value: "unsubscribed" },
  { label: "Suspended", value: "suspended" },
  { label: "Blocked", value: "blocked" },
  { label: "Deleted", value: "deleted" },
];

const accountsOptions: Array<OptionType<AccountFilters>> = [
  { label: "Corporate account", value: AccountFilters.corporateOnly },
  { label: "Test Accounts", value: AccountFilters.testOnly },
  { label: "Not Test Accounts", value: AccountFilters.notTestOnly },
  { label: "Accounts", value: AccountFilters.accountsOnly },
];

export const UsersPage: React.FC = () => {
  const [addNewAccountOpen, setAddNewAccountOpen] = useState(false);
  const [userForAddingToCompany, setUserForAddingToCompany] = useState<UserAdminPage | undefined>(undefined);
  const [editDrawer, setEditDrawer] = useState<string>();
  const { users, loading, pagination, querySort, search, accountFilters, group, stats } =
    useSelector(selectUsersPageState);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [userId, setUserId] = useState<string | undefined>();
  const [pending, setPending] = useState(false);
  const [invoiceDrawer, setInvoiceDrawer] = useState<string | undefined>();
  const MenuItems = useCallback(
    (user: UserAdminPage) => {
      const items: DropItem[] = [
        {
          icon: <EditPencil width={20} height={20} color="#6E7E9D" />,
          text: t("Edit"),
          isAccent: false,
          onClick: () => setEditDrawer(user.id),
          section: 1,
        },
        {
          icon: <Refresh width={20} height={20} color="#6E7E9D" />,
          text: t("Extend Trial"),
          isAccent: false,
          onClick: () => setUserId(user.id),
          section: 1,
        },
        {
          icon: <MoneySquare width={20} height={20} color="#6E7E9D" />,
          text: "Create Invoice",
          isAccent: false,
          onClick: () => setInvoiceDrawer(user.id),
          section: 1,
        },
        {
          icon: <Union width={20} height={20} color="#6E7E9D" />,
          text: "Add user to company",
          isAccent: false,
          onClick: () => setUserForAddingToCompany(user),
          section: 1,
        },
      ];
      return items;
    },
    [language]
  );

  const updateKyc = (id: string, newValue: boolean) => {
    dispatch(updateUserTableAction({ userDto: { id, isCanShare: newValue }, onSuccess: noop }));
    dispatch(setUserKyc({ id, kyc: newValue }));
  };

  const updateUser = (dto: UpdateUserDto) =>
    dispatch(updateUserTableAction({ userDto: dto, onSuccess: () => setEditDrawer(undefined) }));

  const items: TableItem<typeof users[0]>[] = [
    // { label: "ID", render: ({ id }) => id },
    {
      label: "User Name",
      sortKey: "name",
      render: ({ name, id, plan }) =>
        !!plan?.name ? (
          <Link className={styles.link} to={`/users/${id}`}>
            {name}
          </Link>
        ) : (
          <span className={styles.linkDisabled}>
            <WarningTriangleOutline /> {name}
          </span>
        ),
    },
    {
      label: "Email",
      sortKey: "email",
      render: ({ email }) => email,
    },
    {
      label: "Plan",
      render: ({ plan }) => plan?.name ?? "n/a",
    },
    {
      label: "Registration",
      sortKey: "createdAt",
      render: ({ createdAt }) => safeDateFormat(createdAt),
    },
    {
      label: "Tickets",
      sortKey: "openTicketsCount",
      render: ({ openTicketsCount }) => openTicketsCount,
    },
    {
      label: "Status",
      render: ({ status }) => <span className={styles[status]}>{status}</span>,
    },
    {
      label: "Files",
      sortKey: "filesCount",
      render: ({ filesCount }) => filesCount,
    },
    {
      label: "Storage",
      sortKey: "filesSize",
      render: ({ filesSize }) => (fileSizeTsGuard(filesSize) ? "0" : prettySize(filesSize)),
    },
    {
      label: "Plan Start",
      render: ({ plan }) => (plan ? safeDateFormat(plan.created_at) : "N/A"),
    },
    {
      label: "Plan End",
      render: ({ plan }) => (plan ? safeDateFormat(plan.end_at) : "N/A"),
    },
    {
      label: "KYC",
      render: ({ isCanShare, id }) => (
        <Checkbox id="kyc" name="kyc" checked={isCanShare} onChange={() => updateKyc(id, !isCanShare)} />
      ),
    },
    {
      label: "Actions",
      width: 45,
      render: (userDto) => (
        <>
          <DropdownVertical noPaddingIcon dropdowns={MenuItems(userDto)} />
          <EditUserForm
            onSubmit={updateUser}
            user={userDto}
            active={userDto.id === editDrawer}
            onClose={() => setEditDrawer(undefined)}
          />
          <ExtendTrialModal
            onSubmit={(days) =>
              dispatch(
                extendUserTrialAction({
                  userId: userDto.id,
                  days,
                  onSuccess: () => setUserId(undefined),
                  actionPage: "table",
                })
              )
            }
            user={userDto}
            active={userId === userDto.id}
            onClose={() => setUserId(undefined)}
          />
          <CreateInvoice
            pending={pending}
            active={invoiceDrawer === userDto.id}
            onClose={() => setInvoiceDrawer(undefined)}
            userId={userDto.id}
            invoiceType={InvoiceType.user}
            onSubmit={onCreateInvoice}
          />
        </>
      ),
    },
  ];

  const dispatch = useDispatch();
  const getUsers = (...args: Parameters<typeof getUsersAction>) => dispatch(getUsersAction(...args));
  const setQuerySort = (querySort: QuerySort) => dispatch(setQuerySortAction(querySort));
  const setSearch = (value: string) => dispatch(setSearchAction(value));
  const onCreateInvoice = (data: CreateInvoiceDto) => {
    setPending(true);
    dispatch(
      createInvoiceAction({
        data,
        onSuccess: () => {
          setInvoiceDrawer(undefined);
          getUsers();
          setPending(false);
        },
        userId: invoiceDrawer!,
      })
    );
  };
  const clearCache = () => dispatch(clearUsersTableCache());

  const onAccountsFilter = (values: MultiValue<{ label?: string; value?: AccountFilters }> | undefined) => {
    const filters = values?.filter((filter) => filter.value !== undefined).map((filter) => filter.value);
    dispatch(setAccountFilters(filters && filters.length > 0 ? (filters as AccountFilters[]) : []));
  };

  const PaginationComponent = () => (
    <div className={styles.pagination}>
      <PerPageSwitcher value={pagination.perPage} onChange={(x) => getUsers({ pagination: { perPage: x } })} />
      <PageSwitch onChange={(page) => getUsers({ pagination: { page } })} pageInfo={pagination} />
    </div>
  );

  useEffect(() => {
    getUsers({ querySort, search, pagination: { page: 1 } });
  }, [querySort, search, accountFilters, group, pagination.perPage]);

  return (
    <div className={styles.content}>
      <div className="page">
        <div className={styles.topBar}>
          <Heading className={styles.topHeading} variant="h2">
            Users
          </Heading>
          <SearchInput
            defaultValue={search}
            placeholder="Enter name or email"
            onChange={setSearch}
            className={styles.searchInput}
          />
          <PbButton onClick={() => setAddNewAccountOpen(true)} size="lg" color="success">
            Add new account
          </PbButton>
          <PbButton onClick={clearCache} size="lg">
            Clear Table Cache
          </PbButton>
        </div>

        <div>
          <div className={styles.topPagination}>
            <div className={styles.switcherWrapper}>
              <SelectMulti
                className={styles.multiFilter}
                onChange={(value) => onAccountsFilter(value)}
                options={accountsOptions}
              />
            </div>
            <div className={styles.filteredUsers}>
              <span>{stats.count}</span> users found
            </div>
            <div className={styles.additionSelect}>
              <span className={styles.additionLabel}>Show Status:</span>
              <SelectField
                value={group}
                className={styles.select}
                onChange={(value) => dispatch(setUsersStatusGroupAction(value))}
                options={StatusOptions}
              />
            </div>

            <PaginationComponent />
          </div>
          <CommonTable
            idKey="id"
            onSortChange={setQuerySort}
            sortInfo={querySort}
            items={items}
            values={users}
            loading={loading}
          />
          <div className={styles.bottomPagination}>
            <PaginationComponent />
          </div>
        </div>
        {addNewAccountOpen && <AddNewAccount open={addNewAccountOpen} onClose={() => setAddNewAccountOpen(false)} />}
        {userForAddingToCompany && (
          <AddUserToCompany
            user={userForAddingToCompany}
            open={!!userForAddingToCompany}
            onClose={() => setUserForAddingToCompany(undefined)}
          />
        )}
      </div>
    </div>
  );
};
